/* ================================
        #ERROR PAGES
=================================== */
.error-page {
    background: #ffffff;
    box-shadow: 0px 2px 15px #b6d7ff;
    border-radius: 4px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;

    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:before,
        &:after {
            content: "";
            height: 50px;
            display: block;
        }
        @media (max-width: 767px) {
            min-height: calc(100vh - 61px);
        }
        @media (max-width: 1100px) and (min-width: 768px) {
            min-height: calc(100vh - 210px);
            &:before {
                height: 90px;
            }
        }
        @media (max-width: 1199px) and (min-width: 1101px) {
            min-height: calc(100vh - 190px);
            &:before {
                height: 90px;
            }
        }
        @media (min-width: 1200px) {
            min-height: calc(100vh - 266px);
            &:before {
                height: 50px;
            }
        }
        &.unable-to-process {
        }
    }
    &__header {
        padding-top: 5px;
        padding-bottom: 10px;
        padding-left: 35px;
        padding-right: 20px;
        border-bottom: 1px dashed #aec6e8;
        @media (min-width: 576px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-left {
            display: flex;
            align-items: center;
            padding: 20px 0;
            @media (max-width: 575px) {
                flex-direction: column;
            }
        }
        &-title {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 0;
            letter-spacing: 1.125px;
            text-transform: uppercase;
            color: #3e537e;
            @media (max-width: 575px) {
                margin-top: 20px;
            }
        }
        &-icon {
            @media (min-width: 768px) {
                margin-right: 24px;
            }
            @media (max-width: 767px) and (min-width: 576px) {
                margin-right: 14px;
                width: 35px;
            }
        }
        @media (max-width: 767px) {
            padding-left: 20px;
            padding-right: 20px;
        }
        @media (max-width: 575px) {
            padding-bottom: 19px;
        }
    }

    //#BODY
    &__body {
        background: #f6faff;
        padding-top: 30px;
        padding-bottom: 40px;
        padding-left: 36px;
        padding-right: 36px;
        &-text {
            font-size: 14px;
            line-height: 18px;
            color: #3e537e;
            @media (max-width: 1199px) {
                line-height: 20px;
            }
        }
        @media (max-width: 767px) {
            padding-left: 30px;
            padding-right: 30px;
            text-align: center;
        }
    }

    //#BUTTON
    &__btn {
        background: linear-gradient(225deg, #3662ff 0%, #1966ff 100%);
        border-radius: 5px;
        padding: 16px 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
            padding: 15px 21px;
        }
        &-text {
            font-weight: bold;
            font-size: 14px;
            color: #fff;
            margin-left: 16px;
        }
        @media (max-width: 575px) {
            max-width: 163px;
            font-size: 12px;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            padding: 10px 17px;
            &-icon {
                max-width: 17px;
            }
            &-text {
                margin-left: 11px;
                font-size: 12px;
            }
        }
        &--center-align {
            display: table;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
            .error-page__btn-text {
                margin-left: 0;
            }
        }

        &--action {
            display: table;
            margin-left: auto;
            margin-right: auto;
            margin-top: 24px;
            min-width: 140px;
            text-align: center;

            .error-page__btn-text {
                margin-left: 0;
            }
        }
    }

    .error-page__header-right-text {
        font-weight: 600;
        letter-spacing: 0.4px;
        @media (max-width: 575px) {
            text-align: center;
        }
    }
}

body.errorPages{
    background: #224DE5 !important;
    &:before{
        display: none !important;
    }
    #blueCardBgID{
        padding: 0 !important;
    }
}


.error-title{
    font-size: 55px;
    font-weight: bold;
    color:#fff;
    margin-bottom: 16px;
    @include xs{
        font-size: 50px;
    }
    &-sub{
        font-size: 22px;
        color:#fff;
        margin-bottom: 27px;
    }
}


.errorpage-title{
   font-size: 32px;
   font-weight: bold;
   color:#fff;
   margin-bottom: 16px;
   @media (max-width: 341px){
   font-size: 26px;
   }
   @media (min-width: 342px){
       @include sm{
           font-size: 30px;
      }
    }
    @include sm-min{
      @include md{
        margin-top: -50px;
      }
     }
     @include md-min{
        @include lg{
            margin-top: -65px;
        }
    }
    &-sub{
        font-size: 22px;
        color:#fff;
        margin-bottom: 27px;
        @media (max-width: 341px){
        font-size: 18px;
        }
        @media (min-width: 342px){
            @include sm{
                font-size: 21px;
        }
    }
    }
}


.errorpage-btn {
    color:#134EFF;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #fff;
    border: 0;
    border-radius: 31px;
    padding: 16px 95px;
    min-width: auto;
    position: relative;
    &:hover,&:focus,&:active{
    box-shadow: 2px 1px 7px 0px white
}
}



.error-page-wrapper{

    &.vertical-table{
        height: 100vh;
        &__cell{
    padding-top: 80px;
    padding-bottom: 80px;
        }
    }
}
//#ERROR BTN
.error-btn {
    color:#134EFF;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #fff;
    border: 0;
    border-radius: 31px;
    padding: 16px 47px;
    min-width: auto;
    position: relative;
    &:hover,&:focus,&:active{
        box-shadow: 2px 1px 7px 0px white
    }
}
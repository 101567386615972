.vld {

  /* ======#VARIABLES */
  $primary-color: #FF7A00;
  $secondary-color: #2E62F6;
  $light-orange: #FFE0C4;
  $light-purple: #FFE0C4;
  $purple-color: #553DE8;
  $white: #fff;
  $black: #222222;
  $background-color: #F5F8FB;
  $form-bg: #EBF0F5;

/*=================================
  #VERIFY USER
=================================*/
.verify-user-stage{
.verify-user-stage-no{
  background-color: $light-orange !important;
}
}
.verify-user-stage-wrapper{
  &.activated{
    background-color:lighten($color: $light-orange, $amount: 5%);
    .verify-user-stage-no{
      &:after{
        background-color:  $primary-color !important;
      }
    }
  }
}

/* ===========================
    #BOOTOM MENU
============================== */
.boottom-menu__items{
  &:hover,&:focus,&:active,&.active{
      .bottom-menu-icon__hover-fill{
          fill: $primary-color;
      }
  }
}

/* =========================
    #ADD-ON
============================ */
.add-on-options{
  &:checked{
    ~.add-on-options-lable{
      background-color:$purple-color !important; 
      border-color:$purple-color !important;
    }
  }
}
.add-on-price{
  p{
    color: $purple-color !important;
  }
  }
  //CART BAR
  .cart-menu{
  background: $purple-color !important;
  }
  //CART DESK BAR
.total-payable-btn{
  background-color: $primary-color !important;
}

/* =========================
  #NO-POLICY
============================ */
//BCKGROUND COL0R
&.darkBackground{
  background: $purple-color !important;
}
//#SPACE BG
.space-animation__bg-path1{
  fill: darken($color: $purple-color, $amount: 10%);
}
.space-animation__bg-path2{
  fill: darken($color: $purple-color, $amount: 17%);
}
.space-animation__bg-path3{
  fill: darken($color: $purple-color, $amount: 25%);
}

/* ===================
  #PRIMARY BUTTON
======================== */
.primary-button{
  color: $purple-color !important
}
/* ==============================
  #NAVBAR DROPDOWN 
================================= */
.navbar-dropdown-menu__list{
  li{
     &.active{
      .header-right-side-option__two-icon--text{
        background: $light-orange !important;
        color: #222222 !important;
      }
    }
    .header-right-side-option__two-icon--text{
      
      &:hover,&:focus,&:active{
        background:$light-orange !important;

      }
    }
  }
}

/* ================================
      VERIFY FILES
=================================== */
.verify-files-uploading{
  .upload-file-bar{
    span{
      background: $purple-color !important;
    }
    }
}
/* ===============================
        #POLICY OPEN
================================== */
//#TABACCORDION 
.tab-accordion{
.accor-tab-title{
  color:$purple-color !important;
}
.accor-tab:checked + label .accor-tab-title-wrapper:after{
  background: $purple-color !important;
}
}
/* ===============================
        #CANCELLATION
================================== */
.cancelation-type-box{
  .cancelation-type-input{
    &:checked+.cancelation-type-label{
        border-color: $purple-color !important;
    }
}
}
/* ===============================
        #END CANCELLATION
================================== */
/* ===============================
    #POLICY OPEN ACCORDION CARDS 
  ================================*/
.tab-accordion{
  .make-adjustment-section-card__wrapper{
&:after{
  border-color: $purple-color;
}
  }
}

//CIRCLE ANIMATION COLOR
.circle-scale-down:after {
  background: $purple-color
}

}
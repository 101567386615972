/* ============================ *\
        #BASE
\* ============================ */

/* #FONTS
=================== */
.font-size-30 {
  font-size: 30px;

  @media (max-width: 400px) {
    font-size: 23px;
  }
}

.font-size-20 {
  font-size: 20px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-12 {
  font-size: 12px;
}

/* #FONTS-WEIGHTS
=================== */
.font-bold {
  font-weight: bold;
}

/* #COLOR-CLASSES
=================== */
.color-gray {
  color: #909eb9;
}

.color-dark-gray {
  color: #3e537e;
}

/* #BreadCrumb
=================== */
.site-breadcrumb {
  margin-bottom: 22px;
  @extend .color-gray;

  .breadcrumb-item {
    font-weight: bold;
    font-size: 12px;
    background: transparent;
    border: 0;
    outline: 0;

    &--font-14 {
      font-size: 14px;
    }

    @extend .color-gray;

    &:hover,
    &:active,
    &:focus {
      font-weight: 900;
    }
  }

  .breadcrumb-icon {
    margin-right: 8px;

    img {
      vertical-align: text-bottom;
    }
  }
}

/* #BOXES
=================== */
.site-box {
  padding: 20px 22px;
}

.site-box--rounded {
  border-radius: 10px;
}

.primary-box {
  @extend .site-box;
  background-color: #fff;
}

.secondary-box {
  @extend .site-box;
  background-color: #f6faff;
  &--margin{
    margin: 22px 0;
  }
}
.white-box{
  background-color: #fff;
  padding: 15px;
  &__content{
    font-size: 14px;
    line-height: 18px;
    color: #000;
    color: #3E537E;
  }
}

.primary-box-title {
  font-size: 30px;
  color: #3e537e;
  font-weight: bold;
  margin-bottom: 0;

  @media (max-width: 575px) {
    font-size: 21px;
  }
}

.primary-box-title-icons {
  margin-right: 17px;
  align-items: center;
  display: flex;

  @media (max-width: 575px) {
    max-width: 36px;
    margin-right: 13px;

    svg {
      width: 100%;
    }
  }
}

.primary-box-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.primary-box-title-sub {
  font-size: 12px;
  color: #909eb9;
}

.primary-box-bottom-buttons {
  margin-top: 21px;

  @media (max-width: 479px) {
    .custom-back {
      display: inline-block !important;
    }
  }
}

.primary-box-bottom-buttons--right {
  @media (min-width: 480px) {
    float: right;
  }
}

.page-wrapper {
  font-family: "Brandon";
  padding-top: 30px;
  padding-bottom: 80px;

  @media (max-width: 1199px) and (min-width: 992px) {
    padding-top: 60px;
  }
}

//INPUT SPACER
.inputs-margin-bottom-20 {
  margin-bottom: 20px;
}
.inputs-margin-bottom-md-20 {
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.inputs-margin-top-20 {
  margin-top: 20px;
}

//#Text area resize none
.textarea-resize-none {
  resize: none;
}

//#FLEX ROW
.flex-center-row {
  display: flex;
  justify-content: center;

  &:before,
  &:after {
    display: none;
  }
}

.container-820 {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}

.conatiner-770 {
  max-width: 770px;
  margin-right: auto;
  margin-left: auto;
}

.mobile-hidden {
  @media (max-width: 767px) {
    display: none !important;
  }
}

.desk-hidden {
  @media (min-width: 768px) {
    display: none !important;
  }
}

//#MARGIN
.margin-0 {
  margin: 0;
}

//#BG CLASSES
.background-white--sm {
  @media (max-width: 767px) {
    background-color: #fff;
  }
}

//#MAGIC ARROW BTN
.magic-button__arrow {
  height: 12px;
  position: relative;
  top: 2px;
  margin-left: 6px;

  svg {
    height: 12px;
  }
}

.non-ie-show {
  display: none;
}

//#IE HIDDEN
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .non-ie-show {
    display: block;
  }
  .ie-hidden {
    display: none !important;
  }
}

//#RADIO BTNS
.payable-payment-radio {
  .radio-tickmark {
    &:after {
      content: url("/assets/img/radio-tick.svg");
      width: 0 !important;
      height: 0 !important;
      top: 5px !important;
      left: 7px !important;
    }
    &:before {
      height: 28px !important;
      width: 28px !important;
    }
  }
}

//#PAYABLE USER DEATILS SECTION
.payabel-details-section {
  padding: 14px 20px;
  background-color: #f6faff;
  border-top: 1px dashed #aec6e8;
  border-bottom: 1px dashed #aec6e8;
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    display: inline-block;
    height: 25px;
    width: 13px;
    top: -15px;
    background: #dcecff;
  }
  &:after {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    right: 0;
  }
  &:before {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    left: 0;
  }
}

//#PAYABLE PAGES
$primary-border: 1px dashed #aec6e8;

.pre-payment-titles-wrappers {
  margin-bottom: 39px;
}

.pre-payment-main-title {
  font-size: 36px;
  line-height: 42px;
  font-weight: bold;
  color: #3e537e;
  margin: 0;
  align-items: center;

  @media (max-width: 475px) {
    font-size: 26px;
    line-height: 32px;
  }
}

/* ====================
  #AUTO COLLAPSE LIST
======================= */
.auto-collapse-box {
  border-radius: 4px;
  align-items: center;
  transition: height 4s linear;
  height: auto;
  margin-bottom: 12px;

  &.show-collapse {
    height: auto;
    box-shadow: 0px 2px 15px #b6d7ff;

    .auto-collaspe-collapsed-section {
      display: block;
    }
  }
}

.auto-collapse-heading-wrapper {
  margin-left: 25px;
  align-items: center;

  @media (max-width: 767px) {
    margin-left: 23px;
  }
}

.auto-collapse-heading {
  font-size: 18px;
  letter-spacing: 1.125px;
  text-transform: uppercase;
  font-weight: bold;
  color: #353a41;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 16px;
    letter-spacing: normal;
  }
}

.auto-collapse-right-side .auto-collapse-heading {
  color: #353a41;
}

.auto-collapse-visable-section {
  background-color: #fff;
  padding: 17px 20px;
  align-items: center;
}

.auto-collaspe-collapsed-section {
  display: none;
  background-color: #f6faff;
  padding: 0px 20px 24px;
  border-top: $primary-border;
  &.auto-collaspe-collapsed-section--accordion {
    padding: 0;
    background-color: #fff;
  }
}

.auto-collapse-right-side,
.auto-collapse-left-side {
  align-items: center;
}

.auto-collaspe-collapsed__content {
  padding-top: 16px;

  @media (min-width: 768px) {
    padding-top: 13px;
  }

  &.auto-collaspe-collapsed__content--accordion {
    align-items: center;
    padding: 16px 20px;

    @media (min-width: 768px) {
      padding-top: 13px;
    }
  }
}

.auto-collaspe-collapsed__content-text {
  color: #909eb9;
  font-size: 14px;
}

//#accordion
.accordion-collapse-content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height, opacity 200ms ease-in;
  background-color: #f6faff;
}

.accordion-collapse-open {
  ~ .accordion-collapse-content {
    max-height: 100%;
    overflow: visible;
    opacity: 1;
    transition: max-height, opacity 200ms ease-in;
    padding: 10px 20px;

    @media (max-width: 575px) {
      padding: 10px 20px;
    }
  }

  .collapse-icon {
    transform: rotate(-180deg);
    transition: transform 300ms linear;
  }
}

.add-on-plains__sub-text {
  font-size: 14px;
  color: #909eb9;
  margin-top: 3px;

  @media (min-width: 575px) {
    min-width: 371px;
    padding-right: 25px;
    width: 100%;
    text-align: justify;
  }
}

.add-on-plains {
  align-items: center;
}

.add-on-plains-left-side {
  width: 87%;

  @media (min-width: 748px) {
    align-items: center;
  }

  /*     @media (max-width:1199px) and (min-width:992px){
      display: block;
    } */
  // @media (max-width:747px) and (min-width:576px){
  //   display: block;
  //   width: 80%;
  // }
  @media (max-width: 747px) {
    flex-direction: column-reverse;
    width: 80%;
  }
}

//RAYZ CSS
//#Variables for component
$font-family: "Brandon";

/*=================================
  #ADDONS COMPONENT: LEFT SECTION - CONFIRMATION
=================================*/
$addon-padding: 50px;

.addons-component {
  background-color: #fff;
  margin-top: 20px;
  padding: 30px 24px 22px;

  &__top-section {
    border-bottom: 1px dashed #aec6e8;
    padding-bottom: 25px;
  }

  &__bottom-section {
    padding: 28px 26px;
    background: #f6faff;
    border-radius: 4px;
    margin-top: 15px;
  }
}

//#Fonts and text: Top Section
.addons-component {
  &__top-section {
    h3 {
      font-size: 18px;
      letter-spacing: 1.125px;
      text-transform: uppercase;
      color: #3e537e;
      font-family: $font-family;
      font-weight: 500;
      margin-bottom: 30px;
    }

    p {
      font-size: 14px;
      color: #909eb9;
      font-weight: $font-family;
      line-height: 18px;
    }
  }
}

//#Circular Radio
.mr-30 {
  margin-right: 30px;
}

.confirm-radio {
  font-style: normal;
  font-weight: 600;
  font-family: $font-family;

  label {
    padding-top: 6px;
    padding-left: 40px;

    &::before {
      height: 36px;
      width: 36px;
      border: 4px solid #dee7f1;
      border-radius: 50% !important;
    }
  }

  input:checked + label:before {
    background-color: #02cd56 !important;
    color: #fff;
  }

  .tick-radio:after {
    color: #fff !important;
    top: 15px !important;
    left: 7px !important;
  }
}

.form-group--text-right {
  text-align: right;
}

.confirm-fg {
  .form-group {
    margin-bottom: 0;

    .label-agree {
      margin-bottom: 0;
    }
  }
}

/*=================================
  #PAYABLE BOX: RIGHT SECTION
=================================*/
$payable-padding: 20px;

.payable-amt__box {
  background-color: #fff;
  border-radius: 0 0 7px 7px;

  p + p {
    margin-top: 0;
  }
}

.payable-amt__title-box {
  padding: $payable-padding;
  text-align: center;
  padding-bottom: 0;

  h3 {
    margin-bottom: 0;
    font-family: $font-family;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
  }
}

/*===============================
      #Staus Divider Line
===============================*/
$size: 15px;

//#Payable Values
.payable-amt__values {
  padding: $payable-padding;
  padding-top: 0;

  @media (max-width: 500px) {
    &--padding-bottom {
      padding-bottom: 0;
    }
  }
}

.payable-amt__rows {
  margin-bottom: 20px;

  @media (min-width: 992px) {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.payable-abt__values-label {
  font-family: $font-family;
  font-size: 16px;
  line-height: 27px;
  color: #3e537e;
  mix-blend-mode: normal;
  opacity: 0.75;
  font-weight: 600;

  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 13px;
  }

  &--sm {
    font-size: 14px;
  }
}

.payable-amt__values-value {
  font-family: $font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-align: right;
  text-transform: uppercase;
  color: #3e537e;

  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 22px;
  }

  &--font-18 {
    font-size: 18px;
  }

  &--sm {
    font-size: 16px;
  }
}

//#Addon List
.payable-amt__addons-section {
  margin-top: 30px;
  margin-bottom: 10px;

  @media (max-width: 991px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.payable-amt__addons-box {
  max-height: 150px;
  height: 100%;
  overflow-y: auto;

  .payable-amt__addons-list {
    margin-bottom: 9px;

    /* &:last-child {
      margin-bottom: 0;
    } */
  }
}

.payable-amt__addons-box {
  padding: $payable-padding;
  border: 1px dashed #aec6e8;
  background-color: rgba(255, 224, 196, 0.0784313725490196);
}

.payable-amt__addons-img-container {
  width: 35px;
  height: 35px;
  margin-right: 20px;

  img {
    width: 100%;
    height: 100%;
    max-height: 33px;
    display: block;
  }
}

.payable-amt__addons-text-container {
  p {
    @extend .payable-abt__values-label;
    font-weight: 500;
  }
}

//#total-payable-button
.total-payable-btn {
  padding: 20px;
  border: 0;
  background: none;
  background-color: #1865ff;

  @media (min-width: 992px) {
    background-color: #f6fafe;
  }

  border-radius: 0 0 7px 7px;
  width: 100%;
  max-width: 100%;
  box-shadow: none;

  @media (min-width: 992px) {
    pointer-events: none;
  }

  &--padding-bottom {
    @media (max-width: 500px) {
      padding-bottom: 10px;
    }
  }

  &--radious-0 {
    border-radius: 0;
  }

  &:focus,
  &:hover {
    outline: none;
    box-shadow: none;
  }

  &__label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 27px;
    /* identical to box height, or 169% */
    text-transform: uppercase;
    color: #182546;

    @media (max-width: 1199px) and (min-width: 992px) {
      font-size: 14px;
    }

    @media (max-width: 991px) {
      color: #fff;
    }
  }

  &__value {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    text-align: right;
    text-transform: uppercase;
    color: #182546;

    @media (max-width: 1199px) and (min-width: 992px) {
      font-size: 19px;
    }

    @media (max-width: 991px) {
      color: #fff;
    }

    @media (max-width: 500px) {
      font-size: 19px;
    }
  }
}

//#CART BAR
.cart-menu {
  background: #1865ff;
  box-shadow: 0px 9px 10px rgba(93, 128, 188, 0.296875);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 5;

  @media (min-width: 992px) {
    display: none;
  }

  .cart-menu-wrapper {
    display: flex;
    align-content: center;
    padding: 18px 12px;
    max-width: 500px;
    justify-content: space-between;
    margin: auto;
  }

  .cart-text {
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    margin: 0;

    @media (max-width: 500px) {
      font-size: 13px;
    }
  }

  .cart-pipe {
    padding: 0 14px;
  }

  .view-cart-button {
    svg {
      @media (max-width: 500px) {
        height: 20px;
      }
    }
  }

  .view-cart-text {
    padding-right: 13px;
  }
}

.agree-radio-buttons {
  @media (max-width: 467px) {
    display: block;

    .form-group--text-right {
      text-align: left;
      text-align: left;
      margin-top: 12px;
    }
  }

  display: flex;
  justify-content: space-between;
}

//#POP UP
.payable-amt-section--hiiden-mobile {
  max-width: 360px;
  width: 100%;

  @media (max-width: 1199px) and (min-width: 992px) {
    max-width: 293px;
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.cart-modal-dilog {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 66px;
  margin-bottom: 0 !important;
  min-height: auto;
}

.modal-total-payable-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 0;
}

.cart-pop-up-body {
  height: calc(100vh - 200px);
}

.cart-pop-up-content {
  background: #fff;

  @media (max-width: 500px) {
    .cart-pop-up-body {
      height: inherit;
    }
  }
}

.add-on-buttons {
  button {
    box-shadow: none !important;
  }

  .custom-back {
    @media (max-width: 479px) {
      display: inline-block !important;
    }
  }

  @media (max-width: 479px) {
    display: flex;
    flex-direction: column;

    button {
      &:first-child {
        order: 1;
      }
    }
  }
}

.bottom-menu {
  display: none;
}

.payable-payment-radio {
  margin-bottom: 14px;
  margin-top: 20px;
}

.cart-modal-dilog {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 66px;
  margin-bottom: 0 !important;
  min-height: auto;
}

.cart-pop-up-body {
  height: calc(100vh - 142px);
}

.payable-payment-radio {
  .confirm-radio {
    font-size: 16px;
    color: rgba(62, 83, 126, 0.75);
  }
}

/* .white-accordion-wrapper{
  margin-bottom: 12px;
      box-shadow: 0px 2px 15px #B6D7FF;
  .panel-body {
        background: #F6FAFF !important;
        padding: 17px 20px;
            border-top: 1px dashed #AEC6E8;
  }
  .white-accordion-header{
        align-items: center;
    font-weight: 600;
    color: #3E537E !important;
    font-size: 18px;
        background: #fff !important;
        @media (max-width:767px){
          font-size: 16px;
        }
  }
  .panel-heading{
    padding: 17px 20px;
    background-color: #fff;
  }
} */
.add-on-buttons {
  margin-bottom: 50px;
}

.payabelSticky {
  position: sticky;
  top: 121px;
  margin-bottom: 20px;

  @media (max-width: 1199px) and (min-width: 992px) {
    top: 105px;
  }
}

/* .payabelSticky-bottom {
  position: absolute;
  bottom: 18px;
} */

.doYouConfirm {
  max-width: 168px;
  text-align: left;
}

.add-on-row {
  display: flex;
  flex-wrap: wrap;

  &:after,
  &:before {
    display: none;
  }
}

.add-on-price {
  margin-top: 15px;
}

.payable-payment-radio {
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 300px) {
    max-width: 300px;
  }
}

//#ADDONS VIEW MORE
.view-more {
  border: 0;
  padding: 0;
  background: transparent;
  margin-top: 8px;
  font-weight: 600;
  display: block;
  color: #3e537e;
  font-size: 15px;

  @media (min-width: 748px) {
    display: none;
  }
}

.payable-payment-radio--center {
  justify-content: center;
}

.accordion-collapse-content-body {
  padding-bottom: 30px;
  padding-top: 28px;
  border-bottom: $primary-border;

  &:last-child {
    border-bottom: 0;
  }
}

.add-on-plains__sub-text--desk {
  @media (max-width: 747px) {
    display: none;
  }
}

.add-on-plains__sub-text--mobile {
  @media (min-width: 748px) {
    display: none;
  }
}

.total-payable-btn--deposit {
  padding-top: 0;
}

.total-payable-btn--total-monthly {
  padding-bottom: 10px;
}

.addons-component__inputs-wrapper {
  margin-top: 25px;
}

.addons-component__inputs-box {
  @media (min-width: 768px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @media (max-width: 767px) {
    margin-top: 6px;
    margin-bottom: 6px;

    .error_msg {
      margin-bottom: 10px;
    }
  }

  .addons-component__input {
    border: 1px solid #d1deeb;

    &--padding {
      padding: 19px;
    }

    &--email,
    &--phone {
      font-family: "Brandon", sans-serif;
      font-size: 18px;
    }

    &--phone {
      padding-left: 92px;
    }

    .input-mobile-number {
      width: 67px;
      border-right: 1px solid #d1deeb;
      font-size: 18px;
    }
  }
}

//#AGREEMENT
.addons-aggrement-wrapper {
  @media (min-width: 768px) {
    margin-top: 40px;
  }

  @media (max-width: 767px) {
    margin-top: 35px;
  }
}

.aggrement-op-up-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0;
}

.addons-payble-close-btn {
  width: 100%;
  text-align: right;
}

//#AUTO COLLAPSE
.auto-collapse-box {
  @media (max-width: 991px) {
    .auto-collapse-icon {
      display: none;
    }
    .auto-collapse-heading-wrapper {
      margin-left: 0;
    }
  }
  //#COLLAPSE ICON
  .collapse-icon {
    margin-left: 20px;
    @media (max-width: 767px) {
      margin-left: 17px;
    }
    transition: transform 300ms linear;
  }

  //#COLLAPSE TOGGLE
  &.show-collapse {
    //#COLLAPSE ICON
    .collapse-icon {
      transform: rotate(-180deg);
    }
  }
}

.payabel-details {
  &__name {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    color: #3e537e;
  }
  &__vech-name {
    color: #3e537e;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }
}

.payable-deatils-text {
  font-size: 22px;
  text-transform: uppercase;
  color: #3e537e;
  font-weight: bold;
  @media (max-width: 1199px) and (min-width: 992px) {
    font-size: 18px;
  }
  @media (max-width: 991px) {
    font-size: 18px;
  }
  &-sub {
    font-size: 17px;
    @media (max-width: 1199px) and (min-width: 992px) {
      font-size: 14px;
    }
    @media (max-width: 991px) {
      font-size: 15px;
    }
  }
}

.payable-label {
  color: rgba(62, 83, 126, 0.75);
  line-height: 17px;
  font-weight: 500;
  font-size: 14px;
  @media (max-width: 991px) {
    font-size: 13px;
  }
  &--margin {
    @media (min-width: 992px) {
      margin-top: 15px;
      margin-bottom: 22px;
    }
    @media (max-width: 991px) {
      margin-bottom: 12px;
    }
  }
  &--letterspacing {
    letter-spacing: 1px;
  }
}

.payable-vehicals-details {
  padding: 14px 20px;
}

.payable-deatils__col {
  margin-bottom: 20px;
  &-top {
    @media (min-width: 992px) {
      margin-bottom: 30px;
    }
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
  }
}

//#BUTTON
.payable-button {
  background-color: #1865ff;
  width: 100%;
  padding: 26px 30px;
  border-radius: 0;
  &__text {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
  }
  &__icon {
    position: relative;
    transition: left 300ms linear;
  }
  &:hover,
  &:focus,
  &:active {
    &__icon {
      left: 9px;
    }
  }
}

//#PAYABLE FOOTER
.payable-footer {
  border-top: 1px dashed #aec6e8;
  &__content {
    border-radius: 4px;
    padding: 28px 30px;
    @media (min-width: 992px) {
      background-color: #3e537e;
    }
    @media (max-width: 991px) {
      padding: 15px 0;
      border-bottom: 1px dashed #576c8a;
      margin-left: 15px;
      margin-right: 15px;
    }

    &-wrapper {
      margin-top: 14px;
      @media (max-width: 991px) {
        background-color: #3e537e;
      }
    }
    &-text {
      font-size: 26px;
      font-weight: 600;
      color: #fff;
      margin: 0;
      @media (max-width: 992px) and (min-width: 1199px) {
        font-size: 24px;
      }
      @media (max-width: 991px) {
        font-size: 20px;
        line-height: 23px;
      }
    }
  }
  //#QUESTION SECTION
  &__question {
    justify-content: center;
    margin-top: 23px;
    align-items: center;
    background: transparent;
    border: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 991px) {
      padding: 20px 0;
      margin-top: 0;
    }
    &-text {
      color: #3e537e;
      font-size: 14px;
      margin: 0;
      margin-left: 10px;
      font-weight: 600;
      text-decoration: underline;
    }
    @media (max-width: 991px) {
      &-md-white {
        .payable-footer__question-text {
          color: #fff;
        }
        .payable-footer__question-icon {
          path {
            fill: #fff;
          }
        }
      }
    }
    //#PAYABLE FOOTER
    &--pop-up {
      border-top: 1px dashed #aec6e8;
    }
  }
}

//#POP UP
.paybale-popup__header {
  padding: 14px 20px;
  // border-bottom: 1px dashed #AEC6E8;
  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    &-text {
      font-size: 18px;
      text-transform: uppercase;
      color: #3e537e;
      font-weight: 600;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
}

//#PAYABLE MODELS BUTTON
.payment-button-wrapper--payable {
  margin: 0 auto !important;
  max-width: 360px;
  .payment-button {
    border: 2px solid #fff;
    background: transparent;
    color: #fff;
    border-radius: 3px;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 9px 15px;
    width: calc(50% - 8px);
    max-width: 160px;
  }
  &.single-button {
    justify-content: center;
    .payment-button {
      max-width: 299px;
      width: 100%;
    }
  }
}
.payment-buttons__container {
  padding: 22px 15px;
  position: fixed;
  bottom: 0;
  margin: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #1966ff;
}

//#HELP INFO ALIGNMENT
.help-info-icon-alignment {
  position: relative;
  .form-info {
    position: absolute;
  }
}

//#BUTTON LOADER

.button-loader,
.btn-next.button-loader {
  position: relative;
  color: transparent !important;
  opacity: 0.5;
  pointer-events: none;

  .btn-arrow {
    display: none;
  }
  .proceed-svg-hide {
    display: none;
  }

  &:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-left-color: transparent;
    animation: loaderSpin 1.5s infinite linear;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
  }

  &--lg {
    &:before {
      width: 27px;
      height: 27px;
      border-width: 3px;
    }
  }
  .magic-button__text {
    opacity: 0;
  }
}
.payment-loader {
  position: absolute !important;
  &.button-loader {
    opacity: 1;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: #1865ff;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }
  &:before {
    z-index: 3;
  }
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.error_msg--full {
  max-width: 100% !important;
  text-align: left;
}
.radio-error label::before {
  border: 4px solid red !important;
}

//#TOAST MESSAGE
.toast {
  background-color: #f15d5a;
  padding: 18px 25px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  max-width: 1110px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  margin-top: -15px;
  box-shadow: 0px 6px 12px rgba(98, 125, 156, 0.35719);
  transition: transform 400ms linear;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 12;
  opacity: 0;
  transform: translateY(-103%);
  &.showToast {
    opacity: 1;
    transform: translateY(15px);
    @media (max-width: 1199px) and (min-width: 992px) {
      transform: translateY(56px);
    }
    @media (max-width: 991px) {
      transform: translateY(39px);
    }
  }
  &-icon {
    margin-right: 10px;
  }
}

.white-space-pre {
  white-space: pre;
}

//#DRIVER LICENSE UPLOADER PREVIEW
.uploader-wrapper.license-file-uploader {
  @media (min-width: 768px) {
    .file-upload-percentage {
      padding-top: 45px !important;
      padding-right: 22% !important;
      font-size: 15px !important;
    }

    file-preview-item {
      padding: 20px 9px;
    }
    .file-preview-description {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (max-width: 1199px) and (min-width: 992px) {
      .ngx-close-icon-wrapper {
        height: 23px !important;
        width: 23px !important;
        .ngx-close-icon {
          &:after,
          &:before {
            top: 8px !important;
            width: 14px !important;
            left: 2px !important;
          }
        }
      }
    }
  }
}

.uploader-wrapper {
  .file-input {
    display: block !important;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

//#primary-form
.primary-form {
  background-color: #cbe3fe;
  border-radius: 6px;
  margin-top: 30px;
  .about-you-button {
    margin-bottom: 0;
  }
  @media (min-width: 1200px) {
    padding: 30px;
  }
  @media (max-width: 1199px) {
    padding: 20px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #a2bfdf;
    padding-bottom: 30px;
    &-left {
      display: flex;
      align-items: center;
    }
    &-title {
      font-weight: bold;
      font-size: 26px;
      color: #182546;
      margin-left: 14px;
      margin-bottom: 0;
    }
    @media (max-width: 575px) {
      &-title {
        font-size: 20px;
        margin-left: 12px;
      }
      &-icon {
        max-width: 35px;
      }
    }
  }

  //#BUTTONS
  &__transparent-btn {
    border: 0;
    background-color: transparent;
    color: #3662ff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
  }

  //#ELEMETS ALIGNMENTS
  @media (max-width: 1199px) and (min-width: 992px) {
    //#RADIO BUTTONS
    .custom-options .options label {
      padding: 18px 48px;
    }
    //#DATE PICKER
    .select-multi .bootstrap-select > .dropdown-toggle {
      padding-left: 12px !important;
    }
    .bootstrap-select > .dropdown-toggle {
      padding-right: 27px !important;
    }
  }
  @media (max-width: 575px) {
    //#DATE PICKER
    .select-multi .bootstrap-select > .dropdown-toggle {
      padding-left: 12px !important;
    }
    .bootstrap-select > .dropdown-toggle {
      padding-right: 27px !important;
    }
  }
}

//#FORM PREVIEW
.form-preview {
  background: rgba($color: #fff, $alpha: 0.6);
  margin-bottom: 20px;
  border-radius: 6px;
  font-family: "brandon";
  .name-label {
    font-size: 18px;
  }
  @media (min-width: 1200px) {
    padding: 30px;
  }
  @media (max-width: 1199px) {
    padding: 20px;
  }
  @media (min-width: 576px) {
    .name-label {
      font-size: 20px !important;
    }
  }
  .position {
    color: #000 !important;
    font-size: 18px !important;
    @media (min-width: 768px) {
      border-left: 1px solid rgba(49, 49, 49, 0.351644);
      margin-left: 8px;
      padding-left: 8px;
    }
    @media (max-width: 767px) {
      display: block;
    }
  }
  .address_marital,
  .licence_status,
  &__title {
    font-size: 18px !important;
    color: #000 !important;
    font-weight: 500 !important;
  }
  //#ACTION BUTTON
  &__action-btn {
    border: 0;
    background: transparent;
    padding: 0;
    color: #3662ff;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: -27px;
    display: block;
    margin-left: auto;
    @media (max-width: 575px) {
      font-size: 15px;
    }
  }

  &__namelist {
    margin-top: 5px;
    .name-label {
      margin-top: 0;
    }
  }
}

//#CONTNET ALIGNMENT
.driver-form-aligner {
  @media (min-width: 1200px) {
    margin-left: 57px;
  }
}

.dotted-button {
  border: 2px dashed #ffffff;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #3662ff;
  width: 100%;
  background: transparent;
  padding: 24px 12px;
}

.bordered-modal {
  padding: 30px 60px;
  background: #fff;
  max-width: 400px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid #1966ff;
  border-radius: 6px;
  text-align: center;
  @media (max-width: 575px) {
    padding: 30px;
  }

  &__icon{
    width: 25px;
  }

  &__header {
    font-size: 18px;
    letter-spacing: 0.41434px;
    font-weight: 600;
  }
  &__text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.276226px;
    color: #000;
    &-bottom {
      margin-top: 5px;
    }
  }
  &__button {
    width: 100%;
    padding: 15px;
    border: 0;
    margin-top: 20px;
    display: block;
    &--rounded {
      border-radius: 30px;
    }
  }
}

.bordered-modal-btn{
  &-icon{
    width: 18px;
    margin-right: 11px;
  }
  &-content{
    display: flex;
    justify-content: center;
    line-height: 1;
  }
  &-subtext{
    font-size: 10px;
    display: block;
    margin-bottom: 2px;
  }
}

.reminder {
  display: flex;
  border-radius: 10px;
  align-items: center;

  &-wrapper {
    margin-bottom: 50px !important;
    padding-bottom: 10px;
    .slick-dots li {
      margin: 0;
      &.slick-active {
        button {
          &:before {
            color: #2b62f6 !important;
            opacity: 1;
          }
        }
      }
      button {
        &:before {
          color: #2b62f6;
          font-size: 8px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .linebreaker {
      display: none;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
  @media (min-width: 768px) {
    padding: 20px 35px;
  }
  &__content {
    @media (max-width: 767px) {
      max-width: 479px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    @media (min-width: 768px) {
      margin-left: 25px;
      &-align {
        margin-right: 25px;
        max-width: 69%;
      }
    }
    &-inner {
      display: flex;
      align-items: center;
      @media (min-width: 768px) {
        .reminder__text {
          padding-right: 24px;
        }
      }
      @media (min-width: 992px) {
        .reminder__text {
          max-width: 76%;
          width: 100%;
        }
      }
      @media (max-width: 767px) {
        justify-content: center;
        flex-direction: column;
        .reminder__text {
          padding-bottom: 20px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }
  &__sub-title {
    font-size: 12px;
    margin-top: 4px;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
  }
  &__title,
  &__text {
    margin-bottom: 0;
    margin-top: 0;
  }
  &__text {
    font-size: 16px;
    line-height: 22px;
    margin-top: 6px;
  }
  &__title {
    font-weight: bold;
    font-size: 26px;
    line-height: 1;
  }
  &__button {
    border-radius: 5px;
    box-shadow: drop-shadow(0px 2px 16px rgba(76, 78, 83, 0.727));
    background-color: var(--secondary-font-color);
    padding: 13px 31px;
    color: var(--secondary-color);
    display: block;
    white-space: nowrap;
    &-wrapper {
      @media (min-width: 992px) {
        margin-left: auto;
      }
    }
  }

  &-primary {
    background: #2b62f6;
    .reminder__text,
    .reminder__title {
      color: #fff;
    }
    .reminder__title {
      font-weight: bold;
    }
    .reminder__text {
      font-weight: 500;
    }
    .reminder__button {
      box-shadow: drop-shadow(0px 2px 16px rgba(76, 78, 83, 0.727));
      background-color: #fff;
      color: #2b62f6;
    }
  }
  &-secandary {
    background: #ffffff;
    border: 1px solid #dc2323;
    box-shadow: 0px 10px 15px #c3d9f3;
    margin-bottom: 30px;
    .reminder__text {
      color: rgba(10, 10, 10, 0.644122);
    }
    .reminder__title {
      color: #3e537e;
    }
    .reminder__button {
      box-shadow: drop-shadow(0px 2px 16px rgba(76, 78, 83, 0.727));
      background-color: #2b62f6;
      color: #fff;
    }
  }
}

.debt-banner {
  .reminder__content {
    margin-bottom: 0;
  }
}


.call-us-section{
  padding: 0 22px;
  text-align: center;
  &__title{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  &__text{
    font-size: 14px;
    color: #909eb9;
    line-height: 15px;
  }
  &__btn{
    background: #2b62f6;
    border-radius: 26px;
    color: #fff !important;
    margin-top: 12px;
    min-width: 137px;
  }
}


.details-block {
  &__header{
    &-title{
      font-weight: 700;
      font-size: 16px;
    }
    &-text{
      font-weight: 600;
      font-size: 12px;
      color: #26385D;
    }
  }
  }
  
  .details-box{
  
    &__header{
  margin-top: 9px;
  margin-bottom: 9px;
  &-text{
    font-size: 16px;
    font-weight: bold;
  }
    }
  
    &__wrapper{
    padding: 6px 10px 30px;
    background: linear-gradient(175.76deg, #FFFFFF 9.29%, #FFFFFF 88.16%);
    box-shadow: 0px 2px 15px rgba(114, 167, 231, 0.296957);
    }
  
    &__item{
  display: flex;
  justify-content: space-between;
  padding: 9px 12px;
  align-items: center;
  &-label,&-info{
  margin: 0;
  color: #26385D;
  }
  
  &-label{
  
  font-weight: 500;
  max-width: 171px;
  padding-right: 10px;
  
  @include xs-min{
    font-size: 14px;
    line-height: 17px;
  }
  @include xs{
    font-size: 12px;
    line-height: 15px;
  }
  }
  
  &-info{
    font-weight: 900;
    white-space: nowrap;
  
  @include sm-min{
    font-size: 14px;
  }
  @include sm{
    font-size: 12px;
  }
  }
  
  &:nth-child(even){
    background: #F0F4FA;
  }
    }
  
    &__footer{
  
      &-text{
        font-weight: 500;
        font-size: 12px;
        color: #26385D;
        text-align: center;
        line-height: 16px;
        padding: 0 20px;
        @media (max-width: 575px){
        padding: 0 5px;

        }
      }
  
      &-price{
        border-bottom: 1px dashed #AEC6E8;
      padding-left: 12px;
      padding-right: 12px;
      @include xs-min{
      padding-bottom: 25px;
      margin-bottom: 25px;
      }
      @include xs{
      padding-bottom: 20px;
      margin-bottom: 20px;
      }
        &-text{
          font-weight: 700;
          margin: 0;
          @include xs{
            font-size: 14px;
          }
          @include xs-min{
            font-size: 16px;
          }
        }
      }
    }
  }
  
  .cutouts{
    position: relative;
    border-top: 1px dashed #AEC6E8;
  @include xs-min{
    margin: 30px 0px;
  }
    @include xs{
      margin: 24px 0px;
    }
    &::after,&::before{
      content:"";
      width: 30px;
      height: 30px;
      background-color: #f6faff;
      position: absolute;
      border-radius: 50%;
      top: -15px;
    }
    &::before{
      left: -31px;
      box-shadow: inset -11px -1px 13px -4px #b5d0f14d;
    }
     &::after{
      right: -31px;
      box-shadow: inset 11px -1px 13px -4px #b5d0f14d;
    }
  }
  
  .action-button{
    font-size: 12px;
    font-weight: 700;  
    border-radius: 3px;
    text-transform: uppercase;
    border: 0;
    padding: 15px 20px;
    min-width: 120px;
  @include xs{
    padding: 10px 10px;
  }
    &-wrapper{
      max-width: 500px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      @include xs{
        flex-direction: column;
        align-items: center;
      }
      @include xs-min{
        padding-left: 15px;
        padding-right: 15px;
      }
      
    }
  
    &__back{
      background: rgba(166, 174, 194, 0.221779);
      color: #6F7689;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover,&:active,&:focus{
        background: darken($color: rgba(166, 174, 194, 0.221779), $amount: 5);
      }
    }
    &__next{

      @include xs{

    margin-top: 20px;

      }

      background: #02CD56;
      color:#fff;
      &:hover,&:active,&:focus{
        background: darken($color: #02CD56, $amount: 5);
      }
    }
  
    
  }

  .help-info{
    padding: 0;
    border: 0;
    display: inline-flex;
    background: transparent;
    box-shadow: 0px 0px 22px rgba(33, 63, 116, 0.512566);
    border-radius: 50%;
  }

  .day-picker{
    background: #F0F4FA;
    padding: 33px 20px;
    display: flex;
    align-items: center;
    // margin: 0 23px;

    &__wrapper{
      padding: 20px;
    }

    &__header{
      margin-bottom: 14px;
      border-bottom:  1px dashed #AEC6E8;
      padding: 23px 12px;
    }

    &__text{
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    }

    &__title{
    font-size: 25px;
    text-align: center;
    color: #3E537E;
      font-weight: 600;
      // margin: 0 24px 14px;
    }

    &__btn{
      width: 100%;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      border: 1px solid #F3F5F7;
box-shadow: 4px 6px 13px rgba(65, 96, 132, 0.0745301);
border-radius: 3px;
padding: 10px 12px;
background: #FFFFFF;
color: #111;
min-height: 50px;

img{
  margin-right: 12px;
}
      &::after{
        content: "";
        padding: 10px;
        background: url('/assets/img/toggle-arrow.svg') no-repeat;
        background-position: center;
margin-left: auto;
      }
      &-wrapper{
        width: 100%;
      }
    }
    &__label{
      font-size: 14px;
      color: #26385D;
      font-weight: 500;
    white-space: pre;
    margin-right: 13px;

    }
    &__menu{
      position: absolute;
      padding: 12px;
      background: #fff;
      list-style: none;
      box-shadow: 1px 8px 8px 1px rgba(174, 174, 174, 0.24);
      left: 0;
      max-width: 92%;
      right: 0;
      margin: auto;
      border-radius: 6px;
      display: flex;
      flex-wrap: wrap;
      &-item{
        width: 14.26%;
        height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    border-radius: 50%;

    &.disabled{
      pointer-events: none;
      opacity: 0.3;
    }

        &:hover{
          background: rgba(54, 98, 255, 0.271);
        }
        &-btn{
background: transparent;
border: 0;
width: 100%;
font-weight: 700;
font-size: 15px;
color: #111111;
        }
      }

    }

    &__action{
      &-wrapper{
        border-top:  1px dashed #AEC6E8;
        padding: 12px;
        margin-top: 14px;
      }
      background: #3662FF;
      box-shadow: 0px 2px 15px rgba(114, 167, 231, 0.296957);
      border-radius: 6px;
      font-weight: 700;
      font-size: 14px;
      color: #fff;
      width: 100%;
      max-width: 180px;
      border: 0;
      padding: 12px 12px;
      background: #3662FF;
      box-shadow: 0px 2px 15px rgba(114, 167, 231, 0.296957);
      border-radius: 6px;
      margin: auto;
      display: block;
    }
  }

  .closeDay-picker{

    background: transparent;
    border: 0;
    position: absolute;
    right: -6px;
    top: -30px;

  }


  .ncb{
    &-modal{
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(7, 14, 36, 0.8117647059) !important;
      mix-blend-mode: normal !important;
      z-index: 9999999999;
      position: fixed;
      top: 0;
      height: 100%;
      width: 100%;
    &__content{
      max-width: 500px;
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      @media (max-width: 500px){
        max-width: 410px;
      }
    }
    &__header{
      text-align: center;
      background-color: #1563EC;
      padding: 20px;
      @media (max-width: 500px){
       padding: 15px;
       }
      &-title{
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 0;
        @media (max-width: 500px){
          font-size: 20px;
         }
      }
    }

    &__info{
      text-align: center;
      background-color: #fff;
      padding: 20px 40px 20px;
      @media (max-width: 500px){
        padding: 20px 20px 10px;
      }
      &-text1{
        color: #233F6F;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 500px){
         font-size: 16px;
        }
      }
      &-text2{
        color: #233F6F;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-top: 10px;
        @media (max-width: 500px){
          font-size: 16px;
         }
      }
      &-buttons{
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        button{
          width: 100%;
          padding: 15px;
          color: #FFF;
          text-align: center;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          border: 1px solid transparent;
          &:first-child{
            margin-right: 20px;
          }
          @media (max-width: 410px){
            padding: 10px;
          }
        }
        .btn1{
          background-color: #1966FF;
          border:1px solid #76A4FF;
          &:hover{
            background-color: #356bd8;
          }
        }
        .btn2{
          background-color: #02CD56;
          border:1px solid #20E371;
          &:hover{
            background-color: #30bc6a;
          }
        }
      }
    }
   
  }
}

.overflow-hidden{
  overflow: hidden;
}



.loader-btn {
  background-image: url("/assets/img/spinner-white.svg") !important;
  color: transparent !important;
  background-repeat: no-repeat;
  background-position: center;
}



  
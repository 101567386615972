.download-app__btn {
    background: linear-gradient(225deg, #3662ff 0%, #1966ff 100%);
    border-radius: 5px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 11px 9px;
    min-width: 170px;
    justify-content: center;
    // margin-bottom: 11px;
    font-family: "Brandon";
    @include xs-min {
        &:first-child {
            margin-right: 20px;
        }
    }
    @include xs {
        max-width: 248px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12px;
    }
    svg {
        width: 18px;
        margin-right: 8px;
    }
    .download-app__text {
        line-height: 12px;
    }
    .download-app__text-light {
        font-size: 10px;
        text-transform: uppercase;
    }
    b {
        font-size: 18px;
        display: block;
        line-height: 20px;
        text-align: left;
    }
    &:hover,
    &:focus,
    &:active {
        box-shadow: 2px 2px 10px 0px #1966ff;
        color: #fff;
    }
}

.download-app-banner {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 0 20px 16px 20px;

    &__footer {
        margin-top: 30px;
        @include xs-min {
            display: flex;
            justify-content: center;
        }
    }
    &--policy {
        margin-bottom: 46px;
        @include sm-min {
            text-align: left;
            display: flex;
            align-items: center;
            padding: 16px 30px 16px 5px;
            justify-content: space-between;
            .download-app-banner__body {
                width: 50%;
                padding-right: 28px;
            }
            .download-app-banner__footer {
                display: block;
                margin-top: 0;
                .download-app__btn {
                    margin-right: 0;
                    @include xs-min {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .download-app-banner__footer {
            .download-app__btn {
                margin-bottom: 12px;
            }
        }

        @include sm {
            display: none !important;
        }
    }
    &__title {
        font-size: 30px;
        font-weight: bold;
        @include xs {
            font-size: 28px;
        }
    }
    &__text {
        font-size: 14px;
        line-height: 16px;
        max-width: 490px;
        margin-left: auto;
        margin-right: auto;
        font-family: "Brandon";
    }
    &__close {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 16px;
        span {
            background: #43435e;
            height: 2px;
            width: 21px;
            display: block;
            border: 1px solid #43435e;
            &:first-child {
                transform: rotate(45deg) translate(1px, 0);
            }
            &:last-child {
                transform: rotate(-45deg) translate(1px, -1px);
            }
        }
    }
    &__link {
        color: #2c3a55;
        font-size: 14px;
        letter-spacing: 0.322264px;
        font-weight: bold;
        display: inline-block;
        margin-top: 30px;
        cursor: pointer;
        font-family: "Brandon";
        svg {
            margin-bottom: -2px;
            position: relative;
            right: -5px;
            transition: right 300ms linear;
        }
        &:hover,
        &:active,
        &:focus {
            color: #2c3a55;
            svg {
                right: -8px;
            }
        }
    }
}

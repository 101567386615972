//Max Variables
$xs: 575px;
$sm: 767px;
$md: 991px;
$lg: 1199px;
//Max Variables
$xs-min: 576px;
$sm-min: 768px;
$md-min: 992px;
$lg-min: 1200px;
//Max
@mixin lg {
    @media (max-width:#{$lg}) {
        @content;
    }
}

@mixin md {
    @media (max-width:#{$md}) {
        @content;
    }
}

@mixin sm {
    @media (max-width:#{$sm}) {
        @content;
    }
}

@mixin xs {
    @media (max-width:#{$xs}) {
        @content;
    }
}

//Min
@mixin lg-min {
    @media (min-width:#{$lg-min}) {
        @content;
    }
}

@mixin md-min {
    @media (min-width:#{$md-min}) {
        @content;
    }
}

@mixin sm-min {
    @media (min-width:#{$sm-min}) {
        @content;
    }
}

@mixin xs-min {
    @media (min-width:#{$xs-min}) {
        @content;
    }
}

//Custom Screen Sizes
$sz-390: 390px;
@mixin max-390 {
    @media (max-width:#{$sz-390}) {
        @content;
    }
}

@mixin min-390 {
    @media (min-width:#{$sz-390 + 1 }) {
        @content;
    }
}

$sz-375: 375px;
@mixin max-375 {
    @media (max-width:#{$sz-375}) {
        @content;
    }
}

@mixin min-390 {
    @media (min-width:#{$sz-375 + 1 }) {
        @content;
    }
}
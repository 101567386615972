/* =======================*\
   #PAYMENT-MODAL
\* ====================== */

.payment-modal{
.modal-dialog{

    width:95% !important;
    max-width:447px !important;
    font-family:'Brandon',sans-serif;
    /* @media (min-width:576px){
        margin: 1.75rem auto !important;
    }
    margin: .5rem !important; */
    //align center
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100%; */
    @media (min-width:576px){
        min-height: calc(100% - 3.5rem);
        margin: 1.75rem auto !important;
    }
    min-height: calc(100% - 1.7rem);
    margin: .8rem auto !important;
}
.modal-content{
    background: #E4EDFF;
}
.payment-box-wrapper{
    padding:0;
    max-height: 592px;
    overflow-y: auto;
    height: calc(100vh - 65px);
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
        display: none;
      }
}
}
.payment-modal-main-content-wrapper{
    padding:0 44px;
}
.payment-header__main-title{
    font-size: 32px;
text-align: center;
font-weight: bold;
color: #4C4C4C;
margin-top: 33px;
}
.payment-header__sub-title{
    font-size: 14px;
line-height: 16px;
text-align: center;
color: #4C4C4C;
}

//#PAYMENT SLIDER
.payment-select-card-wrapper{
    position: relative;
    padding: 21px 0;
    margin-top: 17px;
    &:after, &:before{
        content: "";
        display: block;
        position: absolute;
        background-color: rgba(228, 237, 255, 0.5);
        width: 11%;
        height: 100%;
        top: 0;
        z-index: 1;
        @media (max-width:369px){
            width:8%;
        }
    }
    &:after{
       /*  background-image: url('/assets/img/mta/angle-group.png');
        background-repeat:no-repeat;  */
        left:0;
    }
    &:before{
/*         background-image: url('/assets/img/mta/angle-group.png');
        background-repeat:no-repeat;  */
        right: 0;
    }
}
.payment-select-card-center-angles{
    &:after, &:before{
        content: "";
        display: block;
        position: absolute;
        width:17%;
        height:100%;
        top:0;
        background-image: url('/assets/img/mta/angle-group.png');
        background-repeat:no-repeat; 
        background-size:contain; 
        @media (max-width:369px){
            width:13%;
        }
    }
    &:after{
        left:0;
        transform: scaleX(-1);
    }
    &:before{
right:0;
    }
}
.select-payment-card__slide{
    @media (max-width:436px){
        margin: 0 10px;
    }
}
.payment-payable-tittle{
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    color: #909EB9;    
    margin: 0;
    font-weight:bold;
}
.payment-payable-amount{
    font-size: 24px;
line-height: 28px;
/* text-align: center; */
margin:0;
color: #4C4C4C;
font-weight: bold;
margin-top: 8px;
span{
    font-size: 18px;
}
}
.payment-payable-icon{
    @media (max-width:475px){
        max-width: 47px;
    }
}
.payment-payable-wrapper{
    display:flex;
    justify-content: space-between;
    margin-top:35px;
    margin-bottom: 26px;
}
.payment-details-collapse{
    border-top: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
    .payment-details-wrapper{
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        background: #F6FAFF;
        /* padding: 17px 24px 24px; */
        transition: height, opacity 200ms ease-in;
    }
    .collapse-icon {
       margin-right: 24px;
      }
&.collapse-openPaymentDetails{
    .payment-details-wrapper{
        max-height: 100%;
        overflow: visible;
        opacity: 1;
        transition: height, opacity 200ms ease-in;
        padding: 17px 24px 24px;
    }
    .collapse-icon {
        transform: rotate(-180deg);
        transition: transform 300ms linear;
      }
}
}
.payment-details-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
    p{
        font-size: 11px;
line-height: 13px;
letter-spacing: 2.5px;
text-transform: uppercase;
font-weight: bold;
color: #000000;
margin: 0;
    }
}
.payment-button-wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 27px;
    margin-bottom: 33px;
.payment-cancle-btn{
    background: transparent;
    padding: 0;
    min-width: auto;
    outline: none;
    box-shadow: none;
}
.payment-pay-btn {
    min-width: 120px;
    padding: 12px 20px;
}
}
.payment-close-btn{
    opacity: 1;
    @media (min-width:575px){
        position: absolute;
        right: -38px;
        top: -12px;
    }
    @media (max-width:574px){
        position: relative;
        margin-left:auto; 
    }
    outline: none;
    box-shadow: none;
    span{
        font-size: 50px;
    text-shadow: none;
    opacity: 1;
    color: #fff;
    font-weight: 500;
    font-family: auto;
    }
}
/* =======================*\
   #END PAYMENT-MODAL
\* ====================== */

/* ===========================
    #BODY COLOR
==============================*/
 .darkBackground{
   .footer-bottom-sticky {
       .foot{
        color: rgba(255, 255, 255, 70%);
       }
   }
} 

/* ======================
 #RECENT QUOTES
========================= */
.recent-quotes{
    .d-flex {
        display: flex;
      }
      .justify-content-between {
        justify-content: space-between;
      }
      .font-size-18 {
        font-size: 18px;
        font-weight: bold;
        @media (max-width:375px){
          font-size:16px;
        }
      }
      .font-size-14 {
        font-size: 14px;
        font-weight: bold;
      }
      .black-color {
        color: #000;
      }
      .gray-color {
        color: #909eb9;
      }
      .font-size-11 {
        font-size: 11px;
        font-weight: bold;
      }
      .d-block {
        display: block;
      }
      .mt-n8 {
        margin-top: -8px;
      }
      .spacer-20 {
        height: 20px;
      }
      .font-weight-normal {
        font-weight: 100;
      }
      .insurance-renewal-icon-media {
        object-fit: contain;
        width: 100%;
        max-height: 16px;
        max-width: 26px;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .insurance-renewal-icon {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        margin-right: 6px;
        background-color: #1966ff;
        position: relative;
      }
}
      .insurance-renewal-slider {
        margin-top: 7px;
      }
      .insurance-renewal-info{
          align-items: center;
      }
.recent-quotes-slider-arrow {
    color: #000;
    font-size: 23px;
    line-height: 20px;
    padding: 0 17px;
    background: transparent;
    outline: none !important;
    border: 0;
    font-weight: 400;
    &:hover,
    &:focus {
      box-shadow: none !important;
    }
    &.prev-slide{
        padding-right: 0;
    }
  }
  
/*   .recentQuotesSlider {
    @media (min-width:768px) {
      margin: 0 -17px;
    }
  } */



  //#UPLOAD MODAL
  .upload-files-doc-details-sub-text{
    color: #3E537E;
    font-size: 14px;
    span{
        font-weight: bold;
        display:block;
    }
  }

  .upload-files-close-button{
      width:100%;
      padding: 17px;
  }
  .upload-files-modal-content{
    height: 100% !important;
    min-height: 320px;
  }
  .upload-files-doc-details{
      margin-top:21px;
  }

  /* =======================
  #BOTTOM MENU
  =========================== */
  .bottom-menu{
      display:none;
      background-color:#fff;
      position:fixed;
      bottom: 0;
      left:0;right:0;
      width:100%;
      z-index: 1051;
      box-shadow: 0px 0px 1px 0px #9d9ea0;
      @media (min-width:992px){
          display: none;
      }

  }
  .bottom-menu-wrapper{
    justify-content: space-between;
    align-items: baseline;
    padding: 10px 12px;
    max-width: 500px;
    margin: auto;
    width: 100%;
    display: flex;
  }
  .boottom-menu__items{
      &:hover,&:focus,&:active,&.active{
          .bottom-menu-icon__hover-fill{
              fill:#1966FF;
              opacity: 1;
          }
          .bottom-menu-icon__hover-opacity{
              opacity: 1;
          }
      }
  }

  /* =============================
   #PRIMARY-BUTOON
  ================================ */
    .primary-button{
    background-color:#fff;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #134EFF;
    outline:none;
    border: 1px solid #fff;
    padding: 14px 15px;
    border-radius:30px;
    font-weight:bold;
    display:inline-block;
    &:hover, &:focus, &:active{
      box-shadow: 1px 3px 10px #827b7b;
    }
  }

  /* ==============================
  #FOOTER HIDDEN MAX-991px
  ================================= */
  .footer-hidden-max-991{
      @media (max-width:991px){
          display:none;
      }
  }

  /* ==============================
        #NO POLICY MODAL
  ================================= */
.no-policy-modal{
    max-width: 600px !important;
    .download-dob-modal{
        min-height:100% !important;
        height:100% !important;
    padding: 1em 0 !important;
    }
    .download-close-btn{
        top: 0 !important;
        color: #000;
        right: 13px !important;
        span{
            color:#000 !important;
        }
    }
}
.no-policy-pop-up-content{
    text-align: center;
    padding: 0 34px;
    @media (max-width:500px){
        padding: 0 2px;
    }
    .no-policy-pop-up-title{
        font-size: 23px !important;
        font-weight: 500 !important;
        letter-spacing: 0.417857px;
        color: #182546 !important;
        line-height: 34px !important;
    }
    .no-policy-pop-up-sub-title{
        font-size: 14px !important;
        line-height: 20px !important;
        margin: 0;
        font-weight: 500 !important;
        letter-spacing: 0.417857px;
        color: #182546 !important;
    }
    .secondary-button {
        margin: 33px 0 10px;
        padding: 16px 55px;
    }
}
  /* ==============================
        #END NO POLICY MODAL
  ================================= */

  /* =================================
        #POLICY BOX
  ==================================== */
  .insurance-renewal-section-box {
  margin-bottom: 30px;
/*   padding-top: 14px;
  padding-bottom: 18px; */
         padding: 28px 25px 31px;
  min-height: 129px;
  height: 100%;
/*   margin-right: 10px;
  margin-left: 10px; */
  display: block;
   background-color: #fff;
box-shadow: 0px 2px 15px #B6D7FF;
    border-radius: 10px;



  @media (min-width:992px) {
/*     margin-right: 0;
    margin-left: 0; */
    max-width: 360px;
    width: 100%;
    margin-right: 30px;
    &:nth-child(even){
      margin-right: 0;
    }
  }
  @media (max-width:991px) and (min-width:768px) {
    margin-right: 10px;
    margin-bottom: 12px;
    &:nth-child(even){
      margin-right: 0;
    }
  }

  @media only screen and (max-width:991px) and (min-width:768px) {
    max-width: 325px;
    width: 100%;
  }

  @media (max-width: 767px) {
    margin-left: 5px !important;
    margin-right: 5px !important;
    margin-bottom: 20px;
   padding: 28px 25px 31px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  @media (max-width:375px){
       padding: 18px 15px 24px;
  }

}
.insurance-renewal-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 14px;
  background-color: #1966ff;
  position: relative;
}
.insurance-renewal-icon-media {
  object-fit: contain;
  width: 100%;
  max-height: 16px;
  max-width: 26px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.insurance-renewal-title{
  margin-bottom: 20px;
      margin-top: 0;
  .insurance-renewal-title--main{
font-size: 18px;
    font-weight: 700;
    color:#000;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    .insurance-renewal-title--icon{
margin-left: 11px;
img{
vertical-align: baseline;
}
    }
  }
  .insurance-renewal-title--main-26{
    font-size: 26px;
    font-weight: bold;
    line-height: 30px;
    color:#000;
  }
  .insurance-renewal-title--sub{
    font-size: 11px;
    font-weight: 700;
    color: #909eb9;
        margin-top: 0;
        line-height: 13px;
        text-transform: uppercase;
        @media (max-width:767px){
          font-size: 10px;
        }
  }
}
.overview-number-main-text{
  font-size: 12px;
line-height: 14px;
letter-spacing: 1px;
color:#000;
font-weight: 500;
@media (max-width:767px){
 font-size: 10px;
line-height: 12px;
letter-spacing: 0.230189px;
text-transform: uppercase;
color: #909EB9; 
margin-top: 4px;
font-weight: bold;
}
}
.overview-number-sub-text{
  font-size: 14px;
line-height: 16px;
letter-spacing: 2px;
color: #000000;
    font-weight: bold;
    margin-top: 3px;
    @media (max-width:767px){
      color: #909EB9;
    }
}
.insurance-renewal-info-left-text{
      color: #000;
      font-size: 14px;
    font-weight: 700;
}
.insurance-renewal-info-right-text{
  font-size: 18px;
    font-weight: 700;
        color: #3662ff;
}

//#RECENT QUOTES
  .insurance-renewal-section-box{
      &.recent-quotes-slide {
        max-width: 100% !important;
        margin: 10px 10px;
        border-radius: 10px;
        background-color: #fff;
        min-height: 129px;
    height: 100%;
    max-height: 160px;
        &:focus,
        &:hover {
          outline: none !important;
        }
      }

  } 
  /* =================================
        #POLICY BOX
  ==================================== */

.vld {
  $primary-color: #FF7A00;
  $secondary-color: #2E62F6;
  $light-orange: #FFE0C4;
  $light-purple: #FFE0C4;
  $purple-color: #553DE8;//SECONDARY COLOR
  $white: #fff;
  $black: #222222;
  $background-color: #EBF0F5;
  $form-bg: #EBF0F5;

  .loadImages {
    background: url('/assets/img/vld/policy-blue-bar.jpg') ,
    url('/assets/img/vld/policy-blue-bar.png') 
      url('/assets/img/vld/spinner90.gif'),
      url("/assets/img/vld/ripple.svg"),
      url('./assets/images/white-right-arrow.svg') ,
      url('/assets/img/vld/message-bg.jpg') ,
      url('/assets/img/vld/policy-blue-bar.jpg') ,
      url("/assets/img/vld/check.svg") ,
      url('/assets/img/vld/download-policy.svg') ,
      url('/assets/img/vld/errorphone.svg') ,
      url('/assets/img/vld/common-icons.png') ,
      url('/assets/img/policies-alt/circle-menu-bg.png') ,
      url('/assets/img/vld/background-images/dasboard/download-icon.svg') ,
      url('/assets/img/vld/background-images/dasboard/req-ncb.svg') ,
      url('/assets/img/vld/background-images/dasboard/address.svg') ,
      url('/assets/img/vld/background-images/dasboard/license.svg'),
      url('/assets/img/vld/background-images/dasboard/submit-ncb.svg') ,
      url('/assets/img/vld/background-images/dasboard/driver.svg') ,
      url('/assets/img/vld/background-images/dasboard/vehicle.svg') !important;
    background-position: -9999px -9999px;
    background-repeat: no-repeat;
  }

  .bg-transparent {
    background-color: transparent !important;
  }

  //#Preloaded images
  .blue-cards {
    background-position: -9999px -9999px;
    background-repeat: no-repeat;
    background-image: url('/assets/img/vld/policy-blue-bar.jpg') !important;
  }

  /*============================
  #BODY BG
  ============================*/
  background: $background-color !important;

  .request-ncb__loader {
    position: relative;
    display: block;

    &::before {
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: url('/assets/img/vld/spinner90.gif');
      content: '';
      background-size: 50px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .make-adjustment-section-bg,
    .text-section-make-adj {
      filter: grayscale(1);
      opacity: 0.3;
    }
  }

  /*===============================
  #LOADER
  ===============================*/
  .vc-loading {
    &::before {
      content: "";
      display: block !important;
      width: 60px !important;
      height: 60px !important;
      background: url("/assets/img/vld/ripple.svg") no-repeat center !important;
      background-size: 100% !important;
      margin: auto !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      right: 0 !important;
    }
  }

  /*=================================
  #NAV PROGRESS
  =================================*/
  .barfiller {
    span {
      background-color: $primary-color !important;
    }
  }

  /*============================
  #NAVBAR COLOR FORM
  ============================*/
  .navbar-middle-options li {
    &.active {
      a {
        background: $light-orange !important;
        color: $black !important;
      }
    }
  }

  .mobile {
    .active a {
      background: $light-orange !important;
      color: $black !important;
    }
  }

  //#Icon User in header
  .header-right-side-option__two-icon {
    background-color: $primary-color !important;
    border-color: $primary-color !important;

    &:hover {
      background-color: darken($color:$primary-color, $amount: 5%) !important;
      border-color: darken($color:$primary-color, $amount: 5%) !important;
    }
  }

  /*============================
  #BLUE CARD FORM
  ============================*/
  .blue-card {
    //The BG FOR LOGIN FORM
    // background-image: url("./assets/img/site-logos/wecomparedirect.svg");
    background-image: none;
    background-color: $white;

    //#BG for email field
    .input-caret-center__input-field {
      background-color: $form-bg;
      color: $black !important;
    }

    //#PLACEHOLDER COLOR FOR INPUT FIELD
    .input-caret-center .input-placeholder-text {
      color: $black;
    }

    //#MAGIC BUTTON IN FORM LOGIN
    .magic-button {
      background-color: $primary-color !important;
      color: $white !important;

      .error-phone {
        background-size: 10px !important;
      }
    }

    //#MAGIC ARROW IN FORM LOGIN
    .magic-arrow {
      background-image: url('./assets/images/white-right-arrow.svg');
    }

    .card-title,
    .card-text {
      color: $black;
    }

    /*============================
    #END OF BLUE CARD FORM
    ============================*/
  }

  /*============================
    #CHATBOT SECTION
    ============================*/
  .chat-messages-wrapper {

    button,
    a {
      border: 1px solid $primary-color;
      background: transparent;
      color: $black;
    }
  }

  .chatbot-horizontal:before {
    background-color: $primary-color;
  }

  .chatbot-horizontal:hover,
  .chatbot-horizontal:focus,
  .chatbot-horizontal:active {
    color: $white;
  }

  /*============================
    #MESSAGEBOX SECTION
    ============================*/
  .message-box {
    background-image: url('/assets/img/vld/message-bg.jpg') !important;
    background-color: #553DE9 !important;
  }

  /*============================
    #UPLOAD BOX SECTION
    ============================*/
  .document-upload-box {
    // background-image: url(/assets/img/policies-alt/new-message-bg.svg);
    // background-image: none;
    // background-color: $primary-color;
    background-image: url('/assets/img/vld/message-bg.jpg') !important;
  }

  /*============================
    #POLICY STATUS: POLICY OPEN PAGE
    ============================*/
  .policies-top-blue-section {
    background: url('/assets/img/vld/policy-blue-bar.png') no-repeat;
    background-color: $purple-color !important;
     background-size: cover;
        background-repeat: no-repeat;
    background-size: cover;
    //     @media (max-width:767px){
    //   background-image: url('/assets/img/vld/policy-blue-bar-mobile.png');
    // }
  }

  /*============================
    #QFA SECTION
    ============================*/
  .vc-get-quote-info .vc-get-quote-info-top {
    background-color: $primary-color;
  }

  /*============================
    #SECONDARY BUTTON
    ============================*/
  .secondary-button {
    background: $primary-color;
    color: $white;
  }

  /*============================
    #SECTION NUMBERS
    ============================*/
  .vc-get-quote-form-item .vc-get-quote-form-heading span,
  .check_point_checked {
    background-color: $primary-color !important;
    color: $white;
  }

  /*============================
    #NAME LABELS
    ============================*/
  .name-label {
    color: $primary-color;
  }

  .edit_driver {
    color: $purple-color !important;
  }

  /*============================
    #BTN NEXT
    ============================*/
  .btn-next {
    background-color: $purple-color !important;
    color: $white !important;
  }

  /*============================
    #LOADING BUTTON
    ============================*/
  .loader-default {
    color: transparent !important;

    &:after {
      background: none !important;
    }
  }

  /*============================
    #THIS IS MY VEHICLE BTN
    ============================*/
  .thisismyvan:hover,
  .thisismyvan:active:hover,
  .thisismyvan:active,
  .thisismyvan:focus {
    background: $purple-color !important;
    color: #fff !important;
  }

  /*============================
    #SELECT BOX
    ============================*/
  .select-box label:before {
    background: url("/assets/img/vld/check.svg") no-repeat center;
  }

  .select-box input:checked+label {
    border-bottom-color: $primary-color;

    p {
      color: $primary-color;
      font-weight: bold;
      ;
    }
  }

  /*============================
    #BTN PRIMARY COLOR: For find post code
    ============================*/
  .btn-primary--color {
    background-color: $purple-color !important;
  }

  /*============================
    #BUTTON RADIO BUTTON COLOR
    #POSTAL CODE SAVE ADDRESS BUTTON
    ============================*/
  .custom-options .options input[type=radio]:checked~label,
  .custom-button-family .save-entry {
    background: $purple-color;
    border-color: $purple-color;
  }

  /*============================
    #SELECTED  MENU OPTIONS
    #AUTOFILL MENU OPTIONS
    ============================*/
  .bootstrap-select>.dropdown-menu li a:hover,
  .bootstrap-select>.dropdown-menu li a:focus,
  .selected a,
  .auto-complete-box li a:hover {
    background-color: $light-orange !important;
  }

  /*============================
    #EDIT TEXT COLOR : Eg Edit in Claims and Convictions, 
    #Postal Code Back
    #Tick Radio
    ============================*/
  .edit-table-btn,
  .back,
  .tick-radio:after {
    color: $purple-color;
  }

  /*============================
    #MODALS BG COLOR
    ============================*/
  .cc_modal {
    .vc-acceptance-criteria .well {
      background-color: $background-color;

      //#MODALS BUTTON
      .common-save {
        @extend .btn-next;
      }

      //#Modal title
      .download-modal-doc-title {
        color: $purple-color;
      }
    }

    .terms-body {
      background-color: $background-color;
    }
  }

  /*============================
    #UPLOAD BOX TEXT
    ============================*/
  .upload-box__lable--bluetext {
    color: $primary-color !important;
  }

  /*============================
    #POLICT DOCUMENTS: Documents not available subtext and request it here text
    ============================*/
  .document-not-available-sub-text,
  .download-policy-contact-btn-let-us-text {
    color: $purple-color !important;
  }

  /*============================
    #POLICT DOCUMENTS: Documents download box
    ============================*/
  .document-download-box {
    background: url('/assets/img/vld/download-policy.svg') no-repeat;
  }

  .document-download-box-main-text {
    color: $black;
  }

  //#ERROR MESSAGE 2
  .error_msg2 {
    color: $purple-color;
  }

  /*============================
    #POLICY DASHBOARD PAGE: Make an adjuctment slick arrows
    ============================*/
  .make-adjustment-section {

    .slick-next:before,
    .slick-prev:before {
      color: $purple-color;
    }
  }

  /*===============================
  #MY PORTAL PROGRESS BAR COLOR
  ===============================*/
  .insurance-renewal-section-box {
    .insurance-progress {
      .insurance-progress__bar-fill {
        background: linear-gradient(182.61deg, $purple-color 0%, $purple-color 100%); //#To be removed later
      }
    }

    .insurance-renewal-info-right {
     &-text{
       color: $purple-color !important;
     }
      .blue-color {
        color: $purple-color !important;
      }
    }

    .insurance-renewal-icon {
      background-color: $primary-color;
    }
  }

  /*===============================
  #QFA
  ===============================*/
  .vc-get-quote-progress {
    svg {
      path {
        stroke: $purple-color !important;
      }
    }
  }

  /*===============================
  #Insurence  Cards: My Portal
  ===============================*/
  .reg-main-card {
    .gb {
      margin-top: -1px;
    }

    .card-up {
      border: 1px solid #E6EBF1;
      border-bottom: 0;
    }
  }

  /*===============================
  #CONTACT US: Blue Box
  ===============================*/
  .blue-box {
    background-color: $primary-color !important;
  }

  /*===============================
  #Session Error Pages
  ===============================*/
  .error-card__section {
    background: $white !important;

    h1 {
      color: $black;
    }

    p {
      color: $black;
    }
  }

  .button-error a p span:last-child {
    color: $purple-color !important;
  }

  .error-phone {
    background: url('/assets/img/vld/errorphone.svg') no-repeat center !important;
  }

  /*===============================
  #UPLOAD BUTTON
  ===============================*/
  .upload-button {
    color: $white !important;
    background-color: $purple-color !important;

    svg {

      path,
      rect {
        fill: $white;
      }
    }
  }

  /*===============================
  #WELCOME SECTION TEXT COLOR
  ===============================*/
  .welcome-section__bottom-area-col-text-wrapper-main-text {
    color: $black !important;
  }

  /*===============================
  #SECTION ICONS CSS
  ===============================*/
  .fill-color-primary {
    fill: $primary-color;
  }

  .fill-color-secondary {
    fill: $purple-color;
  }

  .fill-light-color {
    fill: $white;
  }

  .fill-primary-light{
    fill:$light-orange;
  }
  .fill-primary-lighten-2{
    fill:rgba(255, 159, 71, 0.82);
  }

  .stroke-color-primary {
    stroke: $primary-color;
  }

  /*============================
    #COMMON ICONS
============================*/
  .login-icon-conatiner {
    .login-page-top-icon {
      path {
        fill: $primary-color !important;
      }
    }
  }

  .envelope,
  .calendar,
  .message-icon,
  .star-lg,
  .star-sm,
  .star-xs {
    background-image: url('/assets/img/vld/common-icons.png');
  }

  /*============================
    #END COMMON ICONS
============================*/
  /*============================
    #DASHBOARD ICONS
============================*/
  .make-adjustment-section-bg {
    &.download {
      background-image: url('/assets/img/vld/background-images/dasboard/download-icon.svg');
    }

    &.request-ncb {
      background-image: url('/assets/img/vld/background-images/dasboard/req-ncb.svg');
    }

    &.address {
      background-image: url('/assets/img/vld/background-images/dasboard/address.svg');
    }

    &.license {
      background-image: url('/assets/img/vld/background-images/dasboard/license.svg');
    }

    &.submit-ncb {
      background-image: url('/assets/img/vld/background-images/dasboard/submit-ncb.svg');
    }

    &.driver {
      background-image: url('/assets/img/vld/background-images/dasboard/driver.svg');
    }

    &.vehicle {
      background-image: url('/assets/img/vld/background-images/dasboard/vehicle.svg');
    }

    &.contact {
      background-image: url('/assets/img/vld/background-images/dasboard/contact.svg');
    }
  }

  /*============================
    #END DASHBOARD ICONS
============================*/
  /*============================
    #MESSAGE BOX
============================*/
  .message-inbox {
    .message-inbox-icon--orange {
      background: $primary-color;
    }
  }

  /*============================
    #POLICY OVERVIEW BOX
============================*/
  .overview-right-icon {
    path {
      fill: $primary-color
    }
  }

  /*============================
    #POLICY CIRCLE MENU
============================*/
  .circle-menu-item {
    background-image: url(/assets/img/policies-alt/circle-menu-bg.png), linear-gradient(224.96deg, $purple-color 0%, $purple-color 100%) !important;
    background-repeat: no-repeat;
    background: lighten($color: #000000, $amount: 0)
  }
  .circle-menu-slide--more{
     .circle-menu-item {
    border-color:$purple-color !important;
    .circle-menu-item-title{
    color:$purple-color !important;
    }
  } 
  }

  .circle-menu-item.item-selected {
    background: url(/assets/img/policies-alt/circle-menu-bg.png), linear-gradient(224.96deg, lighten($color: $purple-color, $amount: 5%) 0%, darken($color: $purple-color, $amount: 5%) 100%) !important;
    background-repeat: no-repeat !important;
    box-shadow: 2px 7px 17px #D5DEED !important;
  }

  .blue-card .custom-form-dropdpwn {
    border-color: $primary-color;

    .date-picker-trigger {
      background: transparent url('/assets/images/dropdown-arrow-org.svg') no-repeat right center !important;
      color: $primary-color;
    }
  }

  //#terms label
  .terms-label {
    a {
      color: $purple-color;
    }
  }

  /* ===========================
    Acceptance Criteria
     ============================= */
  .vc-acceptance-criteria {
    p {
      a {
        color: $purple-color;
      }
    }

    .vc-acceptance-criteria-item-heading {
      span {
        background-color: $primary-color;
        color: $white;
      }
    }
  }

  /* ===========================
      #BUTTON LOADER
  ============================= */
  .white-btn-with-arrow--loader {
    .magic-arrow {
      background: url('/assets/img/vld/loader-btn.gif') no-repeat center;
    }
  }

  /* =======================*\
   #DOWNLOAD MODAL TOP ICON
\* ====================== */
  .download-dob-modal-top-icon {
    .download-dob-modal-top-icon__main-fill {
      fill: $purple-color !important;
    }

    .download-dob-modal-top-icon__main-stroke {
      stroke: $purple-color !important;
    }
  }

  /* =======================*\
   #MODIFY DRIVER POP_UP
\* ====================== */
  #acceptance-criteria {
    &.pop_modals {
      background-color: $form-bg;
    }
  }

  /* =======================*\
   #COMPARE_QUOTES PAGE
\* ====================== */
  .quotes-overlay-box {
    background-color: $purple-color !important;
  }

  .compare-quote__main-title {
    span {
      color: $primary-color !important;
    }
  }

  /* =======================*\
   #WALLET PAGE
\* ====================== */
  .payment-card.payment-card--colored {
    background: url('/assets/img/mta/payment-overlay.png'), linear-gradient(209.05deg, $purple-color 1.03%, darken($color: $purple-color, $amount: 10) 93.73%) no-repeat;
  }

  .wallet-add-card {
    background: linear-gradient(209.05deg, $purple-color 1.03%, darken($color: $purple-color, $amount: 10) 93.73%);
  }

  .transections-list-img {

    &.transections-list-img--transection,
    &.transections-list-img--refund {
      background: $primary-color !important;
    }
  }

  .no-transection-main-text {
    color: $purple-color !important;
  }

  /* =======================*\
   #WALLET PAGE
\* ====================== */
  .refer-banner-main-title {
    color: $purple-color !important;
  }

  .refer-link {
    .refer-link-text-input {
      color: $purple-color !important;
    }
  }

  .total-earned-box {
    background-color: $purple-color;
  }

  .total-refered-title {
    color: #2C3A55 !important;
  }

  .refered-user-stauts.invite-again {
    background-color: $purple-color !important;
  }


  /* =======================*\
   #PAYMENTS PAGE
\* ====================== */
  .payment-box {
    background: $white;
  }

  .payment-card-atm {


    // &--active {
    //   background-image: url('/assets/img/vld/payments/payment-card-bg.png');
    // }
  }

  .status-divider-line {

    &:before,
    &:after {
      background: $background-color;
    }
  }

  //#PAYMENT TEXT
  .payment-box__text {
    p {
      color: #909EB9;

      small {
        a {
          color: $purple-color;
        }
      }
    }
  }

  .payment-btn {
    &--cancel {
      background-color: transparent;
      color: black;
    }

    &--pay {
      background: $purple-color;
      color: white;
    }
  }

  .card-processing {
    background-image: url('/assets/img/vld/payments/pos.png');

    @media (max-width:450px) {
      background-image: url('/assets/img/vld/payments/pos-mobile.png');
    }
  }

  .card-curve {
    fill: #543CEA !important;
  }

  .card-bg {
    fill: #5F47F5 !important;
  }

  .refresh-payment {
    background-color: #fff;
    box-shadow: 0px 7px 9px #C0D5FF;

    &:focus,
    &:hover {
      box-shadow: 0px 7px 9px #C0D5FF;
    }
  }

  .payment-box__card-details {
    background: $light-orange;
  }
    /* =======================*\
   #NCB VALIDATIONS
\* ====================== */
  //#MTA VALIDATE ICON REG NO FOR NCB VEHICLE REG
  .regSuccess {
    background-image: url('/assets/img/checked.svg') !important;
  }

  .regFail {
    background-image: url('/assets/img/cancel.svg') !important;
  }

  .regLoading {
    background-image: url("/assets/img/spinner-preloader.svg") !important;
  }

//#PROGRESS FULL BG
.bg-color-blue {
  background:  $primary-color !important;
}
}

.mta {
  // $primary-color: #FF7A00;
  // $secondary-color: #2E62F6;
  // $light-orange: #FFE0C4;
  // $light-purple: #FFE0C4;
  // $purple-color: #553DE8;
  $white: #fff;

  // $page-bg: #DCECFF;
  // $black: #222222;
  // $background-color: #EBF0F5;
  // $form-bg: #EBF0F5;
  .loadImages {
    background: url('/assets/img/policies-alt/policy-blue-bar.png'),
    url('/assets/img/policies-alt/policy-blue-bar-new.png')
      url("/assets/img/policies-alt/new-message-bg.svg"),
      url('/assets/img/policies-alt/circle-menu-bg.png'),
      url('/assets/img/mta/dasboard/download-icon.svg') !important,
      url('/assets/img/mta/dasboard/req-ncb.svg') !important,
      url('/assets/img/mta/dasboard/address.svg') !important,
      url('/assets/img/mta/dasboard/license.svg'),
      url('/assets/img/mta/dasboard/submit-ncb.svg') !important,
      url('/assets/img/mta/dasboard/driver.svg') !important,
      url('/assets/img/mta/dasboard/vehicle.svg') !important,
      url('/assets/img/checked-white.svg') !important,
      url('/assets/img/cancel-white.svg') !important,
      url("/assets/img/spinner-white.svg") !important;
    background-position: -9999px -9999px;
    background-repeat: no-repeat;
  }

  .barfiller span {
    background: #1966FF;
  }

  /* =========================
  #POLICY PAGES TOP HEADER
 =========================== */
  .policies-top-blue-section {
    background: url('/assets/img/policies-alt/policy-blue-bar-new.png') no-repeat;
    background-color: #1966ff;
    background-size: cover;
    background-repeat: no-repeat;
    // @media (max-width:767px){
    //   background-image: url('/assets/img/vld/policy-blue-bar-mobile.png');
    //       background-color: #1966ff;
    // }
  }

  /* =====================
   # MESSAGE BOX
  ======================== */
  .message-box {
    background-image: url("/assets/img/policies-alt/new-message-bg.svg");
    background-color: #1966ff;
  }

  /* ===========================
      #POLICY PAGE CIRCLE MENU
  ============================= */
  .circle-menu-item {
    background: url(/assets/img/policies-alt/circle-menu-bg.png), linear-gradient(224.96deg, #1966ff 90%, rgba(25, 102, 255, 0.722) 90%);
    background-repeat: no-repeat;
  }

  /* ===========================
      #BUTTON LOADER
  ============================= */
  .white-btn-with-arrow--loader {
    .magic-arrow {
      background: url('/assets/loader/loader.gif') no-repeat center;
    }
  }

  /* =======================*\
   #WALLET PAGE
\* ====================== */
  .payment-card.payment-card--colored {
    background: url('/assets/img/mta/payment-overlay.png'), linear-gradient(209.05deg, #1F00FF 1.03%, #009CFF 93.73%) no-repeat;
  }

  .wallet-add-card {
    background: linear-gradient(209.05deg, #1F00FF 1.03%, #009CFF 93.73%);
  }

  .transections-list-img {
    &.transections-list-img--transection {
      background: linear-gradient(225deg, #3662FF 0%, #1966FF 100%), linear-gradient(225deg, #8A00FF 1.03%, #0059FF 95.85%);
    }
  }

  /* =======================*\
   #REWARDS PAGE
\* ====================== */
  .total-earned-box {
    background-color: #1966FF;
  }

  /* =======================*\
   #PAYMENTS PAGE
\* ====================== */
  .payment-box {
    background: $white;
  }

  .payment-card-atm {

    &--active {
      background-image: url('/assets/img/mta/payments/payment-card-bg.png');
    }
  }


  .status-divider-line {

    &:before,
    &:after {
      background: #DCECFF;
    }
  }

  //#PAYMENT TEXT
  .payment-box__text {
    p {
      color: #909EB9;

      small {
        a {
          color: #2E64FF;
        }
      }
    }
  }

  .payment-btn {
    &--cancel {
      background-color: transparent;
      color: black;
    }

    &--pay {
      background: linear-gradient(202.99deg, #3662FF 0%, #1966FF 100%);
      color: white;
    }
  }

  .card-processing {
    background-image: url('/assets/img/mta/payments/pos.png');

    @media (max-width:450px) {
      background-image: url('/assets/img/mta/payments/pos-mobile.png');
    }
  }

  .refresh-payment {
    background-color: #fff;
    box-shadow: 0px 7px 9px #C0D5FF;

    &:focus,
    &:hover {
      box-shadow: 0px 7px 9px #C0D5FF;
    }
  }

  .payment-box__card-details {
    background: #D3E2FF;
  }

    /* =======================*\
   #NCB VALIDATIONS
\* ====================== */
  //#MTA VALIDATE ICON REG NO FOR NCB VEHICLE REG
  .regSuccess {
    background-image: url('/assets/img/checked-white.svg') !important;
  }

  .regFail {
    background-image: url('/assets/img/cancel-white.svg') !important;
  }

  .regLoading {
    background-image: url("/assets/img/spinner-white.svg") !important;
  }


  /* =========================
  #NO-POLICY
============================ */
//BCKGROUND COL0R
&.darkBackground{
  background: #224de5 !important;
}


}

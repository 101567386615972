.fade-up-animation{
    position: relative;
    bottom: -6rem;
    transition: bottom 400ms linear;
    opacity: 0;
    &.scroll-animate{
      bottom:0;
animation: opacityAnimation 400ms linear alternate;
opacity: 1;
    }
}

@keyframes opacityAnimation{
    0%,40%{
    opacity: 0; 
    }
    100%{
        opacity: 1;
    }
}